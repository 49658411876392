<template>
  <button v-if="!link" class="button-base"
    :class="[{ 'secondary': isSecondary }, { 'disabled': isDisabled }]" @click="handleClick">
    <span v-if="!isLoading">{{ text }}</span>
    <span v-else class="loader"><font-awesome-icon :icon="['fa', 'spinner']" /></span>
  </button>
  <a v-else
    target="_blank" :href="link" class="button-base"
    :class="[{ 'secondary': isSecondary }, { 'disabled': isDisabled }]"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'ButtonBase',
  props: {
    text: {
      type: String,
      required: true,
    },
    isSecondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick() {
      if (!this.isLoading && !this.isDisabled) {
        this.$emit('clicked');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$buttonHeight: 35px;
$buttonPadding: 0 45px;

@keyframes loaderAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

button,
a {
  border: 0;
  background-color: $primaryButtonBackground;
  border-radius: $globalBorderRadius;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 700;
  color: $white;
  transition: $transitionBase;
  line-height: $buttonHeight;
  box-shadow: $primaryButtonShadow;
  padding: $buttonPadding;

  &.disabled {
    background-color: $buttonAddToCartDisabledBackground !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 14px;
  }

  &:hover {
    background-color: lighten($primaryButtonBackground, 5%);
    box-shadow: none;
  }
}

a {
  text-decoration: none;
  display: inline-block;
}

.secondary {
  background-color: $secondaryButtonBackground;
  box-shadow: $secondaryButtonShadow;

  &:hover {
    background-color: lighten($secondaryButtonBackground, 5%);
    box-shadow: none;
  }
}

.loader {
  display: inline-block;
  animation: loaderAnimation 1.6s infinite;
  font-size: 13px;
  height: 35px;
}
</style>
