<template>
  <div class='purchase-row'>
    <div class="date-col">{{ date }}</div>
    <div class="orderref-col">{{ orderRef }}</div>

    <a :href="invoiceLink" target="_blank" class="invoice-number">{{ invoiceNumber }}</a>

    <div class="price-col">{{ price | toCurrency }}</div>
    <div class="button-col">
      <base-button
        :text="$t('pages.purchases.buttons.ticketsAndInvoices')"
        :link="ticketLink"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'PurchaseRow',
  props: {
    date: {
      type: String,
      required: true,
    },
    orderRef: {
      type: String,
      required: true,
    },
    invoiceNumber: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    ticketLink: {
      type: String,
      required: true,
    },
    invoiceLink: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleBtnClick() {
      window.open(this.ticketLink, '_blank');
    },
    handleInvoiceDownloadClick() {
      window.open(this.invoiceLink, '_blank');
    },
  },
};
</script>

<style lang='scss' scoped>
.purchase-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  font-weight: 500;

  @media screen and (max-width: $breakpointDownMd) {
    grid-template-columns: repeat(4, 1fr) auto;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: 1fr 1fr 0.8fr auto;
    grid-template-rows: auto auto;
    column-gap: 10px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
}

.invoice-number {
  color: $primaryBlue;
  text-decoration: underline;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: $breakpointDownMd) {
    padding-right: 45px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    padding-right: 0;
    text-align: left;
    padding-left: 20px;
  }
}

.date-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1;
  }

  @media screen and (max-width: $breakpointDownXs) {
    line-height: 1.2;
  }
}

.orderref-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 2 / 1 / 3 / 2;
  }

  @media screen and (max-width: $breakpointDownXs) {
    line-height: 1.2;
  }
}

.price-col {
  @media screen and (max-width: $breakpointDownSm) {
    text-align: left;
    grid-area: 1 / 3 / 3 / 4;
  }

  @media screen and (max-width: $breakpointDownSm) {
    text-align: right;
  }
}

.order-number {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.button-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 4 / 3 / 5;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-area: 3 / 1 / 4 / 4;
  }

  .button-base {
    padding: 0 20px;
    line-height: 26px;

    @media screen and (max-width: $breakpointDownSm) {
      width: 100%;
      font-size: 12px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      margin-top: 10px;
    }

    &::v-deep {
      span {
        @media screen and (max-width: $breakpointDownSm) {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100px;
        }

        @media screen and (max-width: $breakpointDownXs) {
          max-width: none;
        }
      }
    }
  }
}
</style>
