<template>
  <div id='purchases-screen'>
    <div class="container webshop-container">
      <header>
        <h1 class="title">{{ $t('pages.purchases.title') }}</h1>
        <span class="subtitle">{{ $t('pages.purchases.subtitle') }}</span>
      </header>
      <div class="purchases-section">
        <font-awesome-icon v-if="purchases === undefined" class="loader" :icon="['fa', 'spinner']" />
        <template v-if="purchases && purchases.length === 0">
          <span class="empty-text">{{ $t('pages.purchases.emptyState') }}</span>
        </template>
        <template v-if="purchases !== undefined">
          <div class="table-header">
            <span class="col-name" v-for="(header, i) in tableHeaders" :key="i">{{ header }}</span>
          </div>
          <purchase-row
            v-for="(item, i) in purchases" :key="i"
            :date="item.date"
            :orderRef="item.orderIdentifier"
            :invoiceNumber="getInvoiceNumber(item.invoice)"
            :price="item.price"
            :ticketLink="item.ticketDownloadLink"
            :invoiceLink="item.invoiceDownloadLink"
          />
        </template>
      </div>
      <div class="pagination-bottom">
        <transition
          mode="in-out"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <pagination-base
            v-if="pagesNumber !== undefined && pagesNumber !== 0"
            :pagesNumber="pagesNumber"
            :activePageNumber="currentPageNumber"
            @navigateTriggered="handlePagination"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PurchaseRow from '@/components/shared/modules/users/PurchaseRow';
import PaginationBase from '@/components/shared/modules/pagination/PaginationBase';

export default {
  name: 'PurchasesScreen',
  props: {},
  components: {
    PurchaseRow,
    PaginationBase,
  },
  data: () => ({
    pagesNumber: undefined,
    currentPageNumber: 0,
    purchases: undefined,
  }),
  created() {
    this.getPrevPurchases(this.currentPageNumber).then((resp) => {
      this.pagesNumber = Math.ceil(resp.total / resp.limit);
      this.purchases = resp.result;
    });
  },
  computed: {
    tableHeaders() {
      return [
        this.$t('pages.purchases.table.headers.date'),
        this.$t('pages.purchases.table.headers.transactionId'),
        this.$t('pages.purchases.table.headers.invoice'),
        this.$t('pages.purchases.table.headers.price'),
      ];
    },
  },
  watch: {
    currentPageNumber(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.purchases = undefined;
        this.getPrevPurchases(newVal).then((resp) => {
          this.pagesNumber = Math.ceil(resp.total / resp.limit);
          this.purchases = resp.result;
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getPrevPurchases: 'users/getPrevPurchases',
    }),
    handlePagination(pageNumber) {
      this.currentPageNumber = pageNumber;
    },
    getInvoiceNumber(invoice) {
      let localIdentifier;

      if (invoice) {
        localIdentifier = invoice.split('|')[2];
      }
      return localIdentifier;
    },
  },
};
</script>

<style lang='scss' scoped>
$contentMaxWidth: 920px;

@keyframes loaderAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#purchases-screen {
  padding: 50px 0 350px;

  @media screen and (max-width: $breakpointDownMd) {
    padding-bottom: 250px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding-bottom: 150px;
  }
}

header {
  margin: 0 0 50px;

  .title {
    @include titleMixin();
    font-size: 40px;
    line-height: 1;

    @media screen and (max-width: $breakpointDownMd) {
      font-size: 32px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  .subtitle {
    font-weight: 500;
    display: block;
    text-align: center;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

.loader {
  display: inline-block;
  animation: loaderAnimation 1.6s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.empty-text {
  font-weight: 500;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.purchases-section {
  border-radius: $globalBorderRadius;
  border: 1px solid $globalBorderColor;
  background-color: $white;
  padding: 0 0 30px;
  max-width: $contentMaxWidth;
  margin: 0 auto;
  position: relative;
  min-height: 200px;
  transition: $transitionBase;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: $contentMaxWidth;
  padding: 20px 30px;
  border-bottom: 1px solid $globalBorderColor;
  margin: 0 0 25px;

  @media screen and (max-width: $breakpointDownMd) {
    grid-template-columns: 0.7fr 0.7fr 0.8fr 0.8fr 1fr;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: 0.7fr 1.5fr 0.8fr 1fr;
    align-items: center;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .col-name {
    font-weight: 500;

    @media screen and (max-width: $breakpointDownMd) {
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: $breakpointDownSm) {
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
      }

      &:nth-child(3) {
        grid-area: 1 / 2 / 3 / 3;
      }

      &:nth-child(4) {
        grid-area: 1 / 3 / 3 / 4;
      }
    }

    @media screen and (max-width: $breakpointDownXs) {
      &:nth-child(4) {
        text-align: right;
      }
    }

    &:nth-child(3) {
      text-align: center;

      @media screen and (max-width: $breakpointDownXs) {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}

.purchase-row {
  margin: 0 0 35px;
  padding: 0 30px;

  @media screen and (max-width: $breakpointDownMd) {
    margin: 0 0 25px;
  }

  &:last-child {
    margin: 0;
  }
}

.pagination-bottom {
  display: flex;
  justify-content: center;
  margin: 35px 0 0;
}
</style>
