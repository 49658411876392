<template>
  <div class='pagination-base'>
    <div class="pages">
      <div class="navigations">
        <span class="nav-handler first" @click="handleNavClick('first')">
          <icon-arrow-double class="icon" />
        </span>
        <span class="nav-handler prev" @click="handleNavClick('prev')">
          <icon-arrow-down class="icon" :color="'#707070'" />
        </span>
      </div>
      <span v-for="n in pagesNumber" :key="n"
        class="page-number" :class="{ 'active': n === activePageNumber + 1 }"
        @click="handleNavClick('page', n - 1)" >
        {{ n }}
      </span>
      <div class="navigations">
        <span class="nav-handler next" @click="handleNavClick('next')">
          <icon-arrow-down class="icon" :color="'#707070'" />
        </span>
        <span class="nav-handler last" @click="handleNavClick('last')">
          <icon-arrow-double class="icon" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowDown from '@/components/shared/elements/icons/IconArrowDown';
import IconArrowDouble from '@/components/shared/elements/icons/IconArrowDouble';

export default {
  name: 'PaginationBase',
  props: {
    pagesNumber: {
      type: Number,
      required: true,
    },
    activePageNumber: {
      type: Number,
      required: true,
    },
  },
  components: {
    IconArrowDown,
    IconArrowDouble,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleNavClick(methodKey, pageNumber) {
      switch (methodKey) {
        case 'first':
          this.$emit('navigateTriggered', 0);
          break;
        case 'prev':
          this.$emit('navigateTriggered', this.activePageNumber - 1 >= 0 ? this.activePageNumber - 1 : 0);
          break;
        case 'next':
          this.$emit('navigateTriggered', this.activePageNumber + 1 <= this.pagesNumber - 1 ? this.activePageNumber + 1 : this.pagesNumber - 1);
          break;
        case 'last':
          this.$emit('navigateTriggered', this.pagesNumber - 1);
          break;
        case 'page':
          this.$emit('navigateTriggered', pageNumber);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$pageNumberSize: 30px;

.pages {
  display: flex;
  align-items: center;
}

.page-number,
.nav-handler {
  width: $pageNumberSize;
  height: $pageNumberSize;
  border-radius: $globalBorderRadius;
  border: 1px solid $globalBorderColor;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transitionBase;
  cursor: pointer;
  margin: 0 6px 0 0;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin: 0;
  }

  &:hover,
  &.active {
    background-color: $primaryBlue;
    border-color: $primaryBlue;
    color: $white;
  }
}

.navigations {
  display: flex;
  align-items: center;

  .nav-handler {
    &:last-child {
      margin: 0 6px 0 0;
    }

    &:hover {
      &::v-deep {
        path {
          fill: $white;
        }
      }
    }
  }

  .prev {
    .icon {
      transform: rotate(90deg);
      padding-bottom: 2px;
    }
  }

  .first {
    .icon {
      transform: rotate(180deg);
      padding-bottom: 2px;
    }
  }

  .next {
    .icon {
      transform: rotate(-90deg);
    }
  }

}
</style>
